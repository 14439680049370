<template>
  <div class="container">
    <span>系统消息</span>
    <a-empty v-if="msgs.length==0"></a-empty>
    <div class="content" v-if="msgs.length > 0">
      <div class="msgItem" v-for="(item, index) in msgs" :key="item.id">
        <div class="msgBody">
          <div class="title">
            <bell-outlined v-if="item.status==1" />
            <a-badge dot v-if="item.status==0"><bell-outlined /></a-badge>
            <span v-if="item.type==0">登陆消息</span>
            <span v-if="item.type==5">博客状态消息 {{item.msgTime}}</span>
            <span v-if="item.type==4">评论状态消息 {{item.msgTime}}</span>
          </div>
          <div class="msgContent">
            <p v-html="item.content"></p>
          </div>
        </div>
        <div class="operation">
          <a-dropdown class="optItem" >
            <small-dash-outlined style="font-size: 26px;" />
            <template #overlay >
              <a-menu style="margin-top: -18px;">
                <a-menu-item>
                  <alert-outlined style="margin-right: 8px;" />
                  <span @click="delMsg(item)">删除消息</span>
                </a-menu-item>
                <a-menu-item v-if="item.status==0">
                  <edit-outlined style="margin-right: 8px;" />
                  <span @click="signRead(item)">标记已读</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <a-pagination
        style="margin-top: 20px;"
        v-if="msgs.length > 0"
        :total="pagination.total"
        :show-total="total => `总共 ${total} 条消息`"
        :page-size="pagination.size"
        :default-current="1"
        @change="pageChange"
        />
    </div>
  </div>
</template>

<script>
  import * as ACCOUNT from '../../api/account.js';
  import { BellOutlined, SmallDashOutlined, 
    AlertOutlined, EditOutlined } from '@ant-design/icons-vue';
  import { uptUserMsgStore } from '../../utils/account.js';
  export default {
    name: 'SysMsg',
    components: {
      BellOutlined,
      SmallDashOutlined, 
      AlertOutlined,
      EditOutlined
    },
    data() {
      return {
        msgs: [],
        pagination: {
          current: 1,
          total: 0,
          size: 10,
        }
      }
    },
    mounted() {
      this.loadSysMsg(1, 10);
    },
    methods: {
      loadSysMsg(page, size) {
        let params = new FormData()
        params.append('type', 0);
        params.append('page', page)
        params.append('size', size)
        ACCOUNT.srhUserMsgs(params).then(res=>{
          // console.log('sys msgs', res)
          if(res.data.data.total > 0) {
            this.msgs = res.data.data.records;
            this.pagination.current = res.data.data.current;
            this.pagination.total = res.data.data.total;
          }
        })
      },
      pageChange(page, size) {
        this.loadSysMsg(page, size)
      },
      delMsg(item) {
        // console.log('msg', item)
        //delMyMsg
        let _that = this;
        let params = new FormData()
        params.append('id', item.id)
        this.$confirm({
          title: '你确定删除此条消息吗?',
          content: item.content,
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            // console.log('OK');
            ACCOUNT.delMyMsg(params).then(res=>{
              if(res.data.data) {
                _that.$message.success('操作成功');
                uptUserMsgStore()
                _that.loadSysMsg(_that.pagination.current, _that.pagination.size)
              }else {
                _that.$message.warning('操作失败')
              }
            })
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      },
      signRead(item) {
        let params = new FormData()
        params.append('mId', item.id)
        ACCOUNT.userMsgSignRead(params).then(res=>{
          if(res.data.data) {
            this.$message.success('操作成功');
            uptUserMsgStore()
            this.loadSysMsg(this.pagination.current, this.pagination.size)
          }else{
            this.$message.warning('操作失败')
          }
        })
      }
    },
  }
</script>
<style scoped>
  .container {
    width: 625px;
    height: 100%;
    margin-left: 20px;
  }
  .msgItem {
    display: flex;
    text-align: left;
    height: 100%;
    border-radius: 25px;
    border-style: dotted;
    border-color: rgb(96, 193, 245);
    border-width: 2px;
    /* background: #f5f4f4; */
    margin-top: 16px;
  }
  .msgBody {
    width: 590px;
  }
  .title {
    font-size: 18px;
  }
  .msgContent {
    background: #f5f4f4;
    border-radius: 5px;
  }
  .operation {
    float: right;
  }
</style>